<script setup lang="ts">
useHead({
    bodyAttrs: {
        class: 'bg-gray-50 text-gray-900',
    },
});
</script>

<template>
    <div class="h-screen">
        <JobAdBaseNavMobile />
        <div class="flex min-h-screen justify-stretch">
            <aside>
                <JamBaseSidebar>
                    <JobAdNav />
                </JamBaseSidebar>
            </aside>
            <div class="w-full">
                <header
                    v-if="$slots.header"
                    class="md:max-xl:mx-6 mx-5 hidden grow md:flex xl:mx-7"
                >
                    <slot name="header" />
                </header>
                <div class="flex flex-col-reverse pb-[80px] lg:flex-row">
                    <main class="flex grow flex-col">
                        <NuxtLoadingIndicator />
                        <div class="flex grow flex-col">
                            <slot />
                        </div>
                    </main>
                </div>
            </div>
        </div>
        <JamBaseNotificationWrapper />
    </div>
</template>
