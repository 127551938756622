<script setup lang="ts"></script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="leading-icon">
            <path
                id="Vector"
                d="M20.0709 12.34C20.3471 12.34 20.5709 12.1161 20.5709 11.84C20.5709 11.5639 20.3471 11.34 20.0709 11.34V12.34ZM3.4033 11.34C3.12716 11.34 2.9033 11.5639 2.9033 11.84C2.9033 12.1161 3.12716 12.34 3.4033 12.34V11.34ZM10.7165 20.0385C10.9145 20.231 11.231 20.2265 11.4235 20.0285C11.616 19.8305 11.6115 19.514 11.4135 19.3215L10.7165 20.0385ZM3 11.84L2.65148 11.4815C2.55464 11.5756 2.5 11.7049 2.5 11.84C2.5 11.9751 2.55464 12.1044 2.65148 12.1985L3 11.84ZM11.4135 4.35852C11.6115 4.16604 11.616 3.84949 11.4235 3.65148C11.231 3.45348 10.9145 3.449 10.7165 3.64148L11.4135 4.35852ZM20.0709 11.34H3.4033V12.34H20.0709V11.34ZM11.4135 19.3215L3.34852 11.4815L2.65148 12.1985L10.7165 20.0385L11.4135 19.3215ZM3.34852 12.1985L11.4135 4.35852L10.7165 3.64148L2.65148 11.4815L3.34852 12.1985Z"
                fill="#142542"
            />
        </g>
    </svg>
</template>
