<script setup lang="ts">
import IconBack from './icons/IconBack.vue';

const { isInternalUser } = useUser();
const companyId: string = useRoute().params.companyId
    ? String(useRoute().params.companyId)
    : '';
const backUrl = () => {
    return isInternalUser.value
        ? `/p/internal/companies/${companyId}`
        : '/p/jobs/';
};
</script>

<template>
    <div class="k-shell-nav__focus-wrapper">
        <div class="k-shell-nav k-shell-nav--closed h-full">
            <div class="k-shell-nav__inner h-full bg-white">
                <div
                    :class="['k-shell-nav__main', 'h-full', 'flex', 'flex-col']"
                >
                    <!-- header -->
                    <NuxtLink
                        class="k-shell-nav__header mb-4 shrink-0 px-5"
                        to="/"
                    >
                        <!-- brand -->
                        <div class="relative">
                            <BaseLogo
                                class="k-shell-nav__brand k-shell-nav__brand--collapsed mb-2"
                                data-qa="mainNav logo"
                                title="karriere.at Logo"
                            />
                            <BaseText
                                variant="bold"
                                class="k-shell-nav__brand-tagline k-shell-nav__brand-tagline--collapsed absolute text-gray-900"
                                data-qa="mainNav text"
                            >
                                B2B Platform
                            </BaseText>
                        </div>
                    </NuxtLink>
                    <button
                        class="mx-5 flex justify-center rounded bg-gray-50 p-2"
                        data-qa="mainNav toggle"
                        title="Navigation Ein- und Ausklappen"
                        @click="navigateTo(`${backUrl()}`)"
                    >
                        <IconBack width="18" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
$bp-navigation-s: 768px;
$bp-navigation-l: 1920px;

$main-nav-width: 352px;
$main-nav-width--small: 320px;
$main-nav-width--collapsed: 72px;

.k-shell-nav {
    --k-shell-nav-width: #{$main-nav-width--small};

    // overlap content
    @media (min-width: $bp-navigation-s) {
        --k-shell-nav-width: #{$main-nav-width};

        width: $main-nav-width--collapsed;
    }

    // push content
    @media (min-width: $bp-navigation-l) {
        width: auto;
    }
}

.k-shell-nav--closed {
    --k-shell-nav-width: #{$main-nav-width--collapsed};
}

/**
 * Focus Wrapper
 */
.k-shell-nav__focus-wrapper,
.k-shell-nav__focus-wrapper > div:nth-child(2) /** actual k-shell-nav element*/ {
    height: 100%;
}

/**
 * Inner - where the magic happens ;)
 */
.k-shell-nav__inner {
    position: relative;
    width: var(--k-shell-nav-width);
    transition: width 0.2s ease-out;
}

.k-shell-nav:hover .k-shell-nav__inner {
    will-change: width;
}

/**
 * Main
 */
.k-shell-nav__main {
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 60px;
    padding-bottom: var(--space-7);
    overscroll-behavior: contain;

    @media (min-width: $bp-navigation-s) {
        padding-top: var(--space-7);
    }
}

/**
 * Header
 */
.k-shell-nav__header {
    // header will be displayed in a separate nav-bar on mobile devices
    display: none;
    white-space: nowrap;

    @media (min-width: $bp-navigation-s) {
        display: block;
    }
}

/**
 * Brand
 */
.k-shell-nav__brand {
    width: 160px;
    transition:
        clip-path 0.2s ease-out,
        transform 0.3s ease-out;
    transform: translate(0, 0);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.k-shell-nav__brand--collapsed {
    transform: translate(12px, 0);
    clip-path: polygon(0 0, 18px 0, 18px 100%, 0% 100%);
}

.k-shell-nav__brand-tagline {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.1s ease-out;
    transition-delay: 0.1s;
}

.k-shell-nav__brand-tagline--collapsed {
    visibility: hidden;
    opacity: 0;
    transition-delay: 0s;
}
</style>
