<script setup lang="ts">
/**
 * Sidebar is a surface containing supplementary content that are anchored to the left edge of the screen.
 */
const { toggle, isOpen } = useNavigation();
</script>

<template>
    <div
        class="k-sidebar bg-gray-300"
        :class="[isOpen ? 'k-sidebar--visible' : 'drop-shadow']"
    >
        <slot />
        <span class="k-sidebar-gray-area" @click="toggle" />
    </div>
</template>

<style lang="scss">
@import '../../base/assets/variables.scss';

/**
 * default/hidden state
 */
.k-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 40;
    height: 100%;

    // initial hidden on mobile
    transition: transform 0.2s ease-out;
    transform: translate(-100%, 0);

    // ATTENTION: styles declared inside the media-query will override
    // modifier styles (e.g. visible modifier)
    @media (min-width: $bp-navigation-s) {
        // always display the sidebar on desktop devices beside the content
        position: sticky;
        top: 0;
        left: 0;
        height: 100vh;
        transform: none;
    }

    @media print {
        display: none;
    }
}

.k-sidebar-gray-area {
    position: fixed;
    inset: 0;
    z-index: -1;
    visibility: hidden;
    width: calc(100vw - var(--scrollbar-width));
    height: 100%;
    background-color: hsl(0, 0%, 0%, 0.3);
    opacity: 0;
    transition: opacity 0.2s ease-out;
    transition-delay: 0s;
    // hide overlay
    @media (min-width: $bp-navigation-l) {
        visibility: hidden;
    }
}

/**
 * visible state
 */
.k-sidebar--visible {
    transform: none;
}

.k-sidebar--visible .k-sidebar-gray-area {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.18s;
    @media (min-width: $bp-navigation-l) {
        visibility: hidden;
    }
}
</style>
